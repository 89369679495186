// for chart.js
document.addEventListener("turbolinks:load", function () {
  if (document.getElementById("exam_chart")) {
    const ele = document.getElementById("exam_chart")
    const ctx = document.getElementById("exam_chart").getContext("2d")
    const array = JSON.parse(ele.dataset.set)
    // const labels = JSON.parse(ele.dataset.labels);
    //console.log(array);
    const passScoreRate = Number(ele.dataset.pass)

    let examItems = []
    for (let i = 0; i < array.length; i++) {
      const ele = document.getElementById(`exam-result-list-item-${i}`)
      if (!ele) {
        break
      }
      examItems.push(ele)
    }
    const colours = array.map((v, i) =>
      v.pass ? "rgba(82, 198, 149, 0.2)" : "rgba(2, 180, 254, 0.2)"
    )
    const hoveredColours = array.map((v, i) =>
      v.pass ? "rgba(82, 198, 149, 1)" : "rgba(2, 180, 254, 1)"
    )

    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        // labels: labels,
        datasets: [
          {
            data: array,
            backgroundColor: colours,
            borderColor: colours,
            barPercentage: 1, // figma deha 56px
            hoverBackgroundColor: hoveredColours,
            hoverBorderColor: hoveredColours,
            borderWidth: 1,
          },
        ],
      },
      plugins: [
        {
          id: "myEventCatcher",
          beforeEvent(chart, args, pluginOptions) {
            const event = args.event
            if (event.type === "mouseout") {
              // マウスがグラフから外れたときに全部のselectedを外す
              for (let ele of examItems) {
                ele.classList.remove("selected")
                ele.classList.remove("out-of-selected")
              }
            }
          },
        },
      ],
      options: {
        animation: false, // FIXME: アニメーションがおかしいため無効化
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 64,
          },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 50,
              font: {
                family: "Noto Sans JP",
                size: 15.2, // figma deha 15.2 and 11.2
                weight: 400,
              },
              color: "rgba(41, 47, 57, 0.8)",
              callback: function (value, index, ticks) {
                return value + "%     " // padding-leftが指定できないのでスペース、、、悲しいね
              },
            },
            grid: {
              borderDash: [4],
              drawBorder: false, // y 0軸にある縦線
              color: "#bebebe",
              drawTicks: false, // 0軸からちょっと左に出ている線を消す
              drawOnChartArea: true,
            },
          },
          x: {
            display: false,
          },
        },
        parsing: {
          xAxisKey: "label",
          yAxisKey: "value",
        },
        onHover: (e, items) => {
          if (items.length == 0) {
            // バーからのホバーが外れた意味
            for (let ele of examItems) {
              ele.classList.remove("selected")
              ele.classList.remove("out-of-selected")
            }
            return
          }
          // itemsがホバーされた。 itemsは1この想定
          const index = items[0].index
          // console.log(`X: ${index}`);
          for (let ele of examItems) {
            // ホバー以外をホワイト・アウトするため out-of-selected をつける
            ele.classList.add("out-of-selected")
          }
          if (examItems[index]) {
            examItems[index].classList.remove("out-of-selected") // 上で全部つけてるので外す
            examItems[index].classList.add("selected")
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          // これ https://github.com/chartjs/chartjs-plugin-annotation
          annotation: {
            clip: false,
            annotations: {
              baseline: {
                type: "line",
                yMin: 0,
                yMax: 0,
                xMin: -10,
                xMax: array.length,
                borderColor: "rgba(212, 212, 212)",
                borderWidth: 1.5,
                // xAdjust: -10,
              },
              line1: {
                type: "line",
                yMin: passScoreRate,
                yMax: passScoreRate,
                xMax: array.length - 0.7,
                borderColor: "rgba(82, 198, 149)",
                borderDash: [4],
                borderWidth: 1,
                //xAdjust: -10,
              },
              label1: {
                type: "label",
                xValue: array.length,
                yValue: passScoreRate,
                color: "rgba(82, 198, 149)",
                content: ["合格基準"],
                font: {
                  family: "Noto Sans JP",
                  size: 15.4,
                  weight: 700,
                  lineHeight: 1.5,
                },
              },
            },
          },
          tooltip: {
            // Disable the on-canvas tooltip
            enabled: false,
            callbacks: {
              label: function (context) {
                let d = context.dataset.data[context.dataIndex]
                return [d.value, d.pass]
              },
            },
            external: undefined, // tooltipEx
          },
        },
      },
    })
    // 模擬試験のリストアイテムのホバーしたときにバーを強調する
    examItems.forEach((ele, index) => {
      ele.addEventListener("mouseover", (e) => {
        myChart.setActiveElements([
          {
            datasetIndex: 0,
            index: index,
          },
        ])
        //}
        myChart.update()
      })
      ele.addEventListener("mouseleave", (e) => {
        myChart.setActiveElements([])
        myChart.update()
      })
    })
  }

  if (document.getElementById("study_group_chart")) {
    const ele = document.getElementById("study_group_chart")
    const ctx = document.getElementById("study_group_chart").getContext("2d")
    const array = JSON.parse(ele.dataset.set)
    const targetIndex = JSON.parse(ele.dataset.target_index)
    const labels = JSON.parse(ele.dataset.labels)
    const questionCount = JSON.parse(ele.dataset.question_count)
    const avgScore = Number(ele.dataset.avg_score)

    const colours = array.map((_, i) => {
      if (i !== targetIndex) {
        return "#E0E0E0"
      } else {
        return "#02B4FE"
      }
    })

    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            data: array,
            backgroundColor: colours,
            borderColor: colours,
            borderWidth: 1,
            barPercentage: 0.35, // バーの太さpxで指定できない figma deha 16px
            borderRadius: 5,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        scales: {
          y: {
            ticks: {
              font: {
                family: "Noto Sans JP",
                size: 15.2,
                style: "normal",
                weight: 500,
              },
              color: "rgba(54, 61, 83, 1)",
              padding: 33, // FIXME この40がなにかわかっていない
            },
            grid: {
              borderDash: [5],
              drawBorder: false, // 0軸にある縦線
              color: "#bebebe",
              drawTicks: false, // 0軸からちょっと左に出ている線を消す
            },
          },
          x: {
            display: true,
            position: "top",
            min: 0,
            max: questionCount,
            ticks: {
              font: {
                family: "Noto Sans JP",
                size: 14.2, // figma deha 14.2 and 11.2
              },
              color: "rgba(41, 47, 57, 0.7)",
              callback: function (value, index, ticks) {
                return index == 0 ? 0 : value + " 問"
              },
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          // annotation: {
          //   clip: false,
          //   drawTime: "afterDraw",
          //   annotations: {
          //     baseline: {
          //       type: "line",
          //       yMin: -0.5,
          //       yMax: -0.5,
          //       xMin: -10,
          //       xMax: 120, // TODO ここは値をどこからかとってきたほうがいいね
          //       borderColor: "rgba(212, 212, 212)",
          //       borderWidth: 1.5,
          //       // yAdjust: -5,
          //     },
          //     line1: {
          //       type: "line",
          //       xMin: avgScore,
          //       xMax: avgScore,
          //       //yMin: 0,
          //       yValue: 5, // array.length - 1.5,
          //       borderColor: "rgba(173, 188, 194, 1)",
          //       borderDash: [4],
          //       borderWidth: 1,
          //       yAdjust: -10,
          //       adjustScaleRange: false,
          //     },
          //     anno: {
          //       type: "point",
          //       backgroundColor: "rgba(173, 188, 194, 1)",
          //       borderWidth: 0,
          //       pointStyle: "triangle",
          //       radius: 10,
          //       rotation: 180,
          //       xValue: avgScore,
          //       xScaleID: "x",
          //       yAdjust: -10,
          //       yValue: -0.45,
          //       yScaleID: "y",
          //     },
          //   },
          // },
        },
      },
    })
  }
})

function tooltipEx(context) {
  // グラフの棒をホバーしたときのツールチップのテキストを変えるためのコード、使わなくなったのでコメントアウト
  // // Tooltip Element
  // let tooltipEl = document.getElementById("chartjs-tooltip");
  // let d = context.dataset;
  // // Create element on first render
  // if (!tooltipEl) {
  //   tooltipEl = document.createElement("div");
  //   tooltipEl.id = "chartjs-tooltip";
  //   tooltipEl.innerHTML = "<table></table>";
  //   document.body.appendChild(tooltipEl);
  // }
  // // Hide if no tooltip
  // const tooltipModel = context.tooltip;
  // if (tooltipModel.opacity === 0) {
  //   tooltipEl.style.opacity = 0;
  //   return;
  // }
  // // Set caret Position
  // tooltipEl.classList.remove("above", "below", "no-transform");
  // if (tooltipModel.yAlign) {
  //   tooltipEl.classList.add(tooltipModel.yAlign);
  // } else {
  //   tooltipEl.classList.add("no-transform");
  // }
  // function getBody(bodyItem) {
  //   return bodyItem.lines;
  // }
  // // Set Text
  // if (tooltipModel.body) {
  //   const titleLines = tooltipModel.title || [];
  //   const bodyLines = tooltipModel.body.map(getBody)[0];
  //   const date = titleLines[0];
  //   const pass = bodyLines[1];
  //   const score = bodyLines[0];
  //   let innerHtml = '<div class="exam-tooltip">';
  //   innerHtml += `<div style="color:#4C5264; font-weight: bold;">${date}</div>`;
  //   innerHtml += '<div class="d-flex justify-content-space pt-1">';
  //   innerHtml += `<div style="color:#F24A33">${pass}</div>`;
  //   innerHtml += `<div class="ps-2" style="color:#4C5264">${score}%</div>`;
  //   innerHtml += "</div></div>";
  //   let tableRoot = tooltipEl.querySelector("table");
  //   tableRoot.innerHTML = innerHtml;
  // }
  // const position = context.chart.canvas.getBoundingClientRect();
  // const bodyFont = Chart.helpers.toFont(
  //   tooltipModel.options.bodyFont
  // );
  // // Display, position, and set styles for font
  // tooltipEl.style.opacity = 1;
  // tooltipEl.style.position = "absolute";
  // tooltipEl.style.left =
  //   position.left +
  //   window.pageXOffset -
  //   45 +
  //   tooltipModel.caretX +
  //   "px";
  // tooltipEl.style.top =
  //   position.top +
  //   window.pageYOffset -
  //   90 +
  //   tooltipModel.caretY +
  //   "px";
  // tooltipEl.style.font = bodyFont.string;
  // tooltipEl.style.padding =
  //   tooltipModel.padding + "px " + tooltipModel.padding + "px";
  // tooltipEl.style.pointerEvents = "none";
}
