// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "trix"
import "@rails/actiontext"
// import "./application.scss";

require("jquery")
require("@nathanvda/cocoon")
// require('./course.js')
require("./header.js")
require("./customer/regular_qa.js")
require("./customer/certification_detail.js")
require("./customer/quiz.js")
require("./chart.js")
require("./customer/exam_mode.js")
require("./customer/common.js")
require("./customer/hands_on.js")
require("./customer/settings.js")

require("@popperjs/core")

Rails.start()
Turbolinks.start()
ActiveStorage.start()
