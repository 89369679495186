document.addEventListener("turbolinks:load", function () {
  // document.onscroll = () => { // for debugging
  //   console.log(window.scrollY);
  // };

  // 模擬試験で使う
  if (document.getElementById("practice_exam_ux") || document.getElementById("exam_result_ux")) {
    practice_exam_functions()
  }
})
function practice_exam_functions() {
  if (document.getElementById("practice_exam_ux")) {
    // カウントダウンスタート
    let $ = require("jquery")
    require("jquery-simple-timer")($)
    $(".timer").startTimer({
      onComplete: function () {
        console.log("Complete")
        $(".exam_submit").click()
      },
      allowPause: true,
    })

    //「このサイトを離れますか？」警告

    $(window).on("beforeunload", function (e) {
      e.preventDefault()
      e.returnValue = "このページを離れますか？"
      return "このページを離れますか？"
    })

    //試験終了ボタンのみ「このサイトを離れますか？」除外
    // TODO 試験終了のモーダル、、、
    $("input[type=submit]").click(function () {
      $(window).off("beforeunload")
    })
  }

  //「試験終了」ボタンで回答を取得
  /* 回答output
    answers_hash = {
       question_id: [5, 1, 22, ...],
       choice_ids: [[31, 34], [41, 44], [55]....]
     }
    */

  //解答データ初期化
  function init_answer_hash() {
    let answers_hash = { question_id: [], choice_ids: [], flagged: [] }

    $(".question-container").each(function () {
      answers_hash.question_id.push($(this).data("question-id"))
      answers_hash.choice_ids.push([])
      answers_hash.flagged.push(false)
    })

    return answers_hash
  }

  function extract_answers() {
    let answers_hash = init_answer_hash()

    for (i = 0; i < questionCount; i++) {
      document
        .querySelectorAll(
          `.question-container[data-question-index="${i}"] input[type="radio"], .question-container[data-question-index="${i}"] input[type="checkbox"]`
        )
        .forEach((elem) => {
          // console.log(`${i}:${elem.checked} ${elem.value}`);
          if (elem.checked) {
            answers_hash.choice_ids[i].push(Number(elem.value))
          }
        })
      document.querySelectorAll(`.flag_this[data-question-index="${i}"]`).forEach((elem) => {
        // console.log(`${i}:${elem.checked} ${elem.value}`);
        if (elem && elem.classList.contains("is_flagged")) {
          answers_hash.flagged[i] = true
        }
      })
    }
    return answers_hash
  }

  const questionCount = $(".question-container").length

  var myModalEl = document.getElementById("end-exam-mode-modal")
  myModalEl &&
    myModalEl.addEventListener("show.bs.modal", function (event) {
      const answers_node = document.querySelector("#end-exam-mode-modal .exam_answers")

      const answers_hash = answers_node.value ? JSON.parse(answers_node.value) : []

      // console.log(document.querySelectorAll(".flag_this"))[0];
      // //残りの問題があるかどうかの判定
      let isEmptyCheck = (array) => array.length === 0
      let isEmpty = answers_hash.choice_ids.some(isEmptyCheck)
      const unansweredCount = answers_hash.choice_ids.filter(isEmptyCheck).length
      let modalBody = document.querySelector("#end-exam-mode-modal .modal-body")

      console.log("isEmpty", isEmpty)

      if (isEmpty) {
        modalBody.innerHTML = `<b> ${unansweredCount} </b>問の未回答があります。`
      } else {
        modalBody.remove()
      }
      // console.log(answers_hash);
    })

  //「試験終了」ボタン
  $(".exam_submit").on("click", (e) => {
    let myModalEl = document.getElementById("end-exam-mode-modal")
    let modal = bootstrap.Modal.getInstance(myModalEl)
    if (modal) {
      // タイマー終了の場合は存在しない。終了ボタンからクリックしたときだけ存在
      modal.dispose()
    }
  })

  //--------------------------------------------------
  let current = 0

  const questionWrapper = $(".question-wrapper")
  questionWrapper.css("--current", current)
  // FIXME 直接ページに飛んだときにpagenationにcurrentoをつける。うごいてないね
  $(`.pagenation[data-question-index="${current}"]`).addClass("current")

  const getScrollTop = (questionIndex) => {
    const scrollTargetEl = document.querySelector(
      `.question-container[data-question-index="${questionIndex}"]`
    )
    if (!scrollTargetEl) {
      return 0
    }
    // FIXME: CSS変数と連動させる
    const scrollOffset = 76 // ヘッダ分 + 上のカードが見えないくらいのオフセット
    const pos = window.pageYOffset + (scrollTargetEl.getBoundingClientRect().top - scrollOffset)

    /**
     * スクロール量(window.pageYOffset)は(半)整数値になるので、小数点以下は切り捨てる
     * 例: window.scrollTo({ top: scrollTop(38134.600938415526) }) すると window.pageYOffset = 38134
     */
    return Math.floor(pos)
  }

  const scrollToQuestion = (questionIndex) => {
    const pos = getScrollTop(questionIndex)
    window.scrollTo({ top: pos, behavior: "instant" })
    // console.log(`scroll to index ${questionIndex}, ${pos}`)
  }

  const isExamModeResult = questionWrapper.hasClass("oneline")

  if (isExamModeResult) {
    // 模擬試験結果の場合、スクロール時にページネーションのcurrent更新

    let needPagenationUpdate = true
    const scrollDetectionIntervalMs = 200

    const updatePagenation = () => {
      const lastCurrent = current

      // n問目がviewportの半分の位置まできたらcurrent判定する

      if (window.pageYOffset + window.innerHeight / 2 >= getScrollTop(current)) {
        while (
          current < questionCount - 1 &&
          window.pageYOffset + window.innerHeight / 2 > getScrollTop(current + 1)
        ) {
          current++
        }
      } else {
        while (current > 0 && window.pageYOffset + window.innerHeight / 2 < getScrollTop(current)) {
          current--
        }
      }

      if (current !== lastCurrent) {
        // currentを更新
        $(`.pagenation[data-question-index="${lastCurrent}"]`).removeClass("current")
        $(`.pagenation[data-question-index="${current}"]`).addClass("current")
        questionWrapper.css("--current-question", current)
      }
    }

    var scrollEndHandlerTimeoutId
    window.addEventListener("scroll", (e) => {
      /**
       * スクロールイベント時、pagenation更新する。
       * 負荷低減のため、その後一定時間(scrollDetectionIntervalMs)は更新しない。
       *  */
      if (needPagenationUpdate) {
        needPagenationUpdate = false
        updatePagenation()

        setTimeout(() => {
          needPagenationUpdate = true
        }, scrollDetectionIntervalMs)
      }

      /**
       * 上の処理だけではスクロール停止時にupdatePagenationが呼ばれないので、
       * スクロールイベントが停止してから一定時間後にもう一度更新する。
       */
      clearTimeout(scrollEndHandlerTimeoutId)
      scrollEndHandlerTimeoutId = setTimeout(updatePagenation, 100)
    })
  }

  $(".pagenation").on("click", (e) => {
    // 模擬試験結果(=縦一列表示)の場合、スクロールする
    if (isExamModeResult) {
      scrollToQuestion(e.target.dataset.questionIndex)
    }

    let checkedExists = false
    document
      .querySelectorAll(
        `.question-container[data-question-index="${current}"] input[type=checkbox], .question-container[data-question-index="${current}"] input[type=radio]`
      )
      .forEach((elem) => {
        if (elem.checked) {
          checkedExists = true
        }
      })
    if (checkedExists) {
      document
        .querySelectorAll(`.pagenation[data-question-index="${current}"]`) // pagenationは2ヶ所あるため, querySelectorAll(PC用/スマホ用)
        .forEach((el) => {
          el.classList.add("answered")
        })
    } else {
      document.querySelectorAll(`.pagenation[data-question-index="${current}"]`).forEach((el) => {
        el.classList.remove("answered")
      })
    }

    e.preventDefault()
    $(`.pagenation[data-question-index="${current}"]`).removeClass("current")
    const index = $(e.currentTarget).data("question-index")
    current = index
    $(`.pagenation[data-question-index="${current}"]`).addClass("current")
    questionWrapper.css("--current-question", current)

    //最後の問題かどうかみるは最後に走らせる
    // last_question_detecion(current, questionCount);
  })

  // 戻る
  $(".navigate_prev_q.prev-button").on("click", (e) => {
    e.preventDefault()

    let checkedExists = false
    document
      .querySelectorAll(
        `.question-container[data-question-index="${current}"] input[type=checkbox], .question-container[data-question-index="${current}"] input[type=radio]`
      )
      .forEach((elem) => {
        if (elem.checked) {
          checkedExists = true
        }
      })
    if (checkedExists) {
      document.querySelectorAll(`.pagenation[data-question-index="${current}"]`).forEach((el) => {
        el.classList.add("answered")
      })
    } else {
      document.querySelectorAll(`.pagenation[data-question-index="${current}"]`).forEach((el) => {
        el.classList.remove("answered")
      })
    }

    if (current == 0) {
      // 循環はしない
      // $(`.pagenation[data-question-index="${current}"]`).removeClass("current");
      // $(`.pagenation[data-question-index="${questionCount - 1}"]`).addClass(
      //   "current"
      // );
      // current = questionCount - 1;
      // questionWrapper.css("--current-question", current);
      // document.querySelector(`.question-wrapper`).dataset.current = current; // これ通常問題でもやったほうがいい
    } else {
      $(`.pagenation[data-question-index="${current}"]`).removeClass("current")
      $(`.pagenation[data-question-index="${current - 1}"]`).addClass("current")
      current = current - 1
      questionWrapper.css("--current-question", current)
      document.querySelector(`.question-wrapper`).dataset.current = current // これ通常問題でもやったほうがいい
    }
  })

  // 進む
  $(".navigate_next_q.next-button").on("click", (e) => {
    e.preventDefault()
    let checkedExists = false
    document
      .querySelectorAll(
        `.question-container[data-question-index="${current}"] input[type=checkbox], .question-container[data-question-index="${current}"] input[type=radio]`
      )
      .forEach((elem) => {
        if (elem.checked) {
          checkedExists = true
        }
      })
    if (checkedExists) {
      document.querySelectorAll(`.pagenation[data-question-index="${current}"]`).forEach((el) => {
        el.classList.add("answered")
      })
    } else {
      document.querySelectorAll(`.pagenation[data-question-index="${current}"]`).forEach((el) => {
        el.classList.remove("answered")
      })
    }

    // console.log("jump to current", current);
    if (current == questionCount - 1) {
      // 循環はしない
      // $(`.pagenation[data-question-index="${current}"]`).removeClass("current");
      // $(`.pagenation[data-question-index="${0}"]`).addClass("current");
      // questionWrapper.css("--current-question", 0);
      // current = 0;
    } else {
      $(`.pagenation[data-question-index="${current}"]`).removeClass("current")
      $(`.pagenation[data-question-index="${current + 1}"]`).addClass("current")
      questionWrapper.css("--current-question", current + 1)
      current = current + 1
    }
  })

  //フラグ
  $(".flag_this").click(function () {
    $(this).toggleClass("is_flagged")
    $(`.pagenation[data-question-index="${current}"] .flag-badge`).toggleClass("hidden")

    //フラグと答えを保存する
    const answers_node = document.querySelector("#end-exam-mode-modal .exam_answers")
    let answers_hash = extract_answers()
    answers_node.value = JSON.stringify(answers_hash)
  })

  //キーボード（右、左）で動かす。
  document.querySelectorAll(".exam_modes_show").forEach((element) => {
    document.onkeydown = (e) => {
      e = e || window.event
      if (e.repeat) {
        return //押しっぱなしはやめ
      }
      if (e.key === "ArrowRight") {
        // 今表示しているquestion-containerがどれか同定できないので取り急ぎ0の進むを押す。戻るも同様
        setTimeout(() => {
          $('.navigate_next_q.next-button[data-question-index="0"]').click()
        }, 10) // 高速で移動されても困るので遅延しとく
      } else if (e.key === "ArrowLeft") {
        setTimeout(() => {
          $('.navigate_prev_q.prev-button[data-question-index="1"]').click()
        }, 10) // 高速で移動されても困るので遅延しとく
      }
    }
  })

  // 選択肢のラジオボタンにフォーカスがあるときに左右キーを押したとき、ラジオボタンの移動を防ぐ
  $('input[type="radio"]').keydown(function (e) {
    if (e.key === "ArrowRight" || e.key == "ArrowLeft") {
      $(this).blur()
      document.dispatchEvent(new KeyboardEvent("keydown", { key: e.key }))
      return false
    }
  })

  // TODO: 模擬試験結果画面は縦長なので、左右キーでスクロールさせる
  // スクロール時にcurrentの更新が必要
  // if (isExamModeResult){
  //   document.addEventListener('keydown', )
  // }

  // document.onkeydown = checkKey;
  // function checkKey(e) {
  //   // FIXME 動いてない
  //   e = e || window.event;
  //   if (e.keyCode == "37") {
  //     $(".navigate_next_q.next-button").click();
  //   } else if (e.keyCode == "39") {
  //     $(".navigate_next_q.prev-button").click();
  //   }
  // }

  //1問も解いていない場合でも、一旦解答を詰め込む
  const answers_node = document.querySelector("#end-exam-mode-modal .exam_answers")
  let answers_hash = extract_answers()
  if (answers_node) {
    // answer_node がすでに消失しているケースがある
    answers_node.value = JSON.stringify(answers_hash)
  }

  // チェックしたときに親のノードにSelectedをつけて色を付ける。もっと簡単な方法あるかも
  // 同じことを通常問題のほうに必要かも
  document.querySelectorAll(".quiz-choice").forEach((choice) => {
    choice.addEventListener("click", (e) => {
      const target = e.target
      // console.log("target", target);
      const input = target.parentNode.querySelector(".form-check-input[type=radio]")
      if (input) {
        //console.log("input", target.closest(".question-content"));
        target.parentNode.parentNode.querySelectorAll(".quiz-choice").forEach((cho) => {
          cho.classList.remove("selected")
        })

        choice.classList.add("selected")
      }

      const ra = target.parentNode.querySelector(".form-check-input[type=checkbox]")
      if (ra && !ra.checked) {
        //console.log("input", target.closest(".question-content"));
        // if (target.parentNode.parentNode.querySelectorAll(".quiz-choice.form-check-input:checked").length > 2) {
        //   target.parentNode.parentNode.querySelectorAll(".quiz-choice").forEach((cho) => {
        //     cho.classList.remove("selected");
        //   });
        // }

        choice.classList.remove("selected")
      } else {
        choice.classList.add("selected")
      }

      //答えを保存する
      const answers_node = document.querySelector("#end-exam-mode-modal .exam_answers")
      let answers_hash = extract_answers()
      if (answers_node) {
        // answer_node がすでに消失しているケースがある
        answers_node.value = JSON.stringify(answers_hash)
      }

      // 全て回答済みの場合、試験を終了するボタンを青くする
      const isNotEmpty = (choiceArray) => choiceArray.length != 0
      if (answers_hash["choice_ids"].every(isNotEmpty)) {
        let examModeButton = document.querySelector(".pre-end-exam-mode-button")
        examModeButton.style.border = "#0db8ff"
        examModeButton.style.backgroundColor = "#0db8ff"
      }
    })
  })
}

// function last_question_detecion(num, total) {
//   if (total - 1 == num) {
//     $(".navigate_next_q .callout").text("問1へ進む");

//     function appear() {
//       //$(".navigate_next_q .callout").text('問1へ進む');
//       var leftVal =
//         $(".navigate_next_q")[total - 1].getBoundingClientRect().left;
//       var widthVal =
//         $(".navigate_next_q")[total - 1].getBoundingClientRect().width;
//       var topVal = $(".navigate_next_q")[total - 1].getBoundingClientRect().top;
//       //$("#end_exam_mode").addClass("show");

//       document.querySelector(".navigate_next_q.next-button").style.display =
//         "none";
//       document.querySelector("#end_exam_mode").classList.add("show");
//     }
//     setTimeout(appear, 300);
//   } else {
//     $(".navigate_next_q .callout").text("次の問へ進む");
//     $("#end_exam_mode").removeClass("show");
//   }
// }
