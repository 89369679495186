import flashAlert from "../biz/flash"

document.addEventListener("turbolinks:load", function () {
  if (
    location.hostname.includes("cloudpanda") &&
    !window.location.pathname.match(/\/hands_ons\//)
  ) {
    // ToBでの共通処理もここに
    // 右クリック禁止
    document.oncontextmenu = function () {
      return false
    }
    let customerBody = document.getElementsByClassName("customer-body")
    if (customerBody && customerBody[0]) {
      customerBody[0].style.userSelect = "none"
    }
  }

  // 設定のページ
  if (document.getElementById("setting-main-view")) {
    console.log("setting-main-view")
    confirm_email_change_modal()
    change_setting_content()
    let viewEl = document.getElementById("setting-main-view")
    if (!viewEl) {
      return
    }
    let stripe = stripe_init(viewEl)

    const url = window.location.href.replace(/#.*/, "") // hash以下を削除

    // paymentModalInit(stripe, url + "#change-plan")

    // changePaymentMethodModalInit(stripe)
  }

  if (document.querySelector(".certification_main_view")) {
    console.log("certification_main_view")
    let viewEl = document.querySelector(".certification_main_view")
    let stripe = stripe_init(viewEl)

    const url = window.location.href.replace(/#.*/, "") // hash以下を削除

    // paymentModalInit(stripe, url)
  }
})

function confirm_email_change_modal() {
  const modal = document.querySelector("#modal-confirm-email-change")
  let visible = false
  // $("#confirm-email-change-link").on("click", (e) => {
  //   e.preventDefault();
  //   $(modal).addClass("visible");
  //   visible = true;
  // });
  // 以下の人らも BS のモーダルを利用しよう
  $("#modal-confirm-email-change").on("click", (e) => {
    if (visible && e.target !== e.currentTarget) {
      $(modal).removeClass("visible")
      visible = false
    }
  })
  $(".btn-modal-close").on("click", (e) => {
    $(modal).removeClass("visible")
    visible = false
  })
}

function change_setting_content() {
  $("#change-profile-link").on("click", (e) => {
    e.preventDefault()
    document.querySelectorAll(".content-wrapper").forEach(function (el) {
      if (el.id === "change-profile") {
        el.classList.add("visible")
      } else {
        el.classList.remove("visible")
      }
    })
    document.querySelectorAll(".setting-sidebar-box .nav-item").forEach(function (el) {
      let link = el.querySelector(".main_callout_view_item_list")
      if (link && link.id !== "change-profile-link") {
        el.classList.remove("menu-active")
      } else {
        el.classList.add("menu-active")
      }
    })
  })
  $("#change-password-link").on("click", (e) => {
    e.preventDefault()
    document.querySelectorAll(".content-wrapper").forEach(function (el) {
      if (el.id === "change-password") {
        el.classList.add("visible")
      } else {
        el.classList.remove("visible")
      }
    })
    document.querySelectorAll(".setting-sidebar-box .nav-item").forEach(function (el) {
      let link = el.querySelector(".main_callout_view_item_list")
      if (link && link.id !== "change-password-link") {
        el.classList.remove("menu-active")
      } else {
        el.classList.add("menu-active")
      }
    })
  })

  $("#change-plan-link").on("click", (e) => {
    e.preventDefault()
    document.querySelectorAll(".cert-content-wrapper .content-wrapper").forEach(function (el) {
      if (el.id === "change-plan") {
        el.classList.add("visible")
      } else {
        el.classList.remove("visible")
      }
    })
    document.querySelectorAll(".setting-sidebar-box .nav-item").forEach(function (el) {
      if (el.id !== "change-plan-link") {
        el.classList.remove("menu-active")
      } else {
        el.classList.add("menu-active")
      }
    })
  })

  if (location.hash) {
    let urlHash = location.hash //アンカー取得
    if (urlHash === "#change-plan") {
      document.querySelectorAll(".cert-content-wrapper .content-wrapper").forEach(function (el) {
        if (el.id === "change-plan") {
          el.classList.add("visible")
        } else {
          el.classList.remove("visible")
        }
      })
      document.querySelectorAll(".setting-sidebar-box .nav-item").forEach(function (el) {
        if (el.id !== "change-plan-link") {
          el.classList.remove("menu-active")
        } else {
          el.classList.add("menu-active")
        }
      })
      window.location.hash = ""
    }
  }

  // パスワード変更でエラー
  document.querySelectorAll("#change-password #new_user").forEach((element) => {
    element.addEventListener("ajax:error", (event) => {
      console.log(JSON.stringify(event.detail))
      const msgEle = document.querySelector("#change-password-message")

      if (event.detail[0].messages) {
        msgEle.innerHTML = event.detail[0].messages.map((s) => `<li>${s}</li>`).join("")
      } else {
        msgEle.innerHTML = "<li>パスワードの変更に失敗しました。</li>"
      }
      msgEle.classList.add("visible")

      const currentPasswordEle = element.querySelector("#user_current_password")
      currentPasswordEle.classList.remove("added-alert")
      if (event.detail[0].errors["current_password"]) {
        currentPasswordEle.classList.add("added-alert")
      }
      const passwordEle = element.querySelector("#user_password")
      passwordEle.classList.remove("added-alert")
      if (event.detail[0].errors["password"]) {
        passwordEle.classList.add("added-alert")
      }
    })
    element.addEventListener("ajax:success", (event) => {
      const msgEle = document.querySelector("#change-password-message")
      msgEle.classList.remove("visible")

      const currentPasswordEle = element.querySelector("#user_current_password")
      const passwordEle = element.querySelector("#user_password")

      ;[currentPasswordEle, passwordEle].forEach((ele) => {
        ele.classList.remove("added-alert")
        ele.value = ""
      })

      flashAlert("パスワードを変更しました。", "info")
    })
  })

  // パスワード変更でエラー
  document.querySelectorAll("#change-profile #new_user").forEach((element) => {
    element.addEventListener("ajax:success", (event) => {
      flashAlert("アカウント情報を変更しました。", "info")
    })
  })
}

function stripe_init(viewEl) {
  const stripePublishableKey = viewEl.dataset.stripePublishableKey
  var stripe = Stripe(stripePublishableKey)
  return stripe
}

function paymentModalInit(stripe, returnUrl) {
  let elements = stripe.elements()
  const elementStyles = {
    base: {
      color: "#32325D",
      fontWeight: 500,
      fontFamily: "Noto Sans JP",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      colorBackground: "#ffffff",
      borderColor: "#E0E6EB",
      "::placeholder": {
        color: "#CFD7DF",
      },
      ":-webkit-autofill": {
        color: "#e39f48",
      },
    },
    invalid: {
      color: "#E25950",
      "::placeholder": {
        color: "#FFCCA5",
      },
    },
  }
  const elementClasses = {
    focus: "focused",
    empty: "empty",
    invalid: "invalid",
  }
  let cardNumber = elements.create("cardNumber", {
    style: elementStyles,
    classes: elementClasses,
    showIcon: true,
  })
  cardNumber.mount("#card-number")
  cardNumber.focus()

  let cardExpiry = elements.create("cardExpiry", {
    style: elementStyles,
    classes: elementClasses,
  })
  cardExpiry.mount("#card-expiry")

  let cardCvc = elements.create("cardCvc", {
    style: elementStyles,
    classes: elementClasses,
  })
  cardCvc.mount("#card-cvc")

  var myModalEl = document.getElementById("payment-method-modal")
  myModalEl &&
    myModalEl.addEventListener("show.bs.modal", function (event) {
      let paymentForm = document.getElementById("payment-submit")
      if (paymentForm) {
        paymentForm.addEventListener("click", (evt) => {
          evt.preventDefault()
          const err = changeLoadingState(true, "登録中...", "登録する")
          if (err) {
            return
          }
          if (!cardNumber) return
          // create new payment method & create subscription
          // createPaymentMethod({ stripe, cardNumber });
          let submitBtn = document.getElementById("payment-submit")
          let messageEle = document.getElementById("payment-method-error-message")
          let emailEl = document.getElementById("payment-email")
          let passwordEl = document.getElementById("payment-password")
          createSubscription(
            stripe,
            cardNumber,
            emailEl && emailEl.value,
            passwordEl && passwordEl.value,
            returnUrl,
            (event) => {
              displayError(event, submitBtn, myModalEl, messageEle)
            }
          )
        })
      }
      // ページ移動の警告 不要？
      // $(window).on("beforeunload", function (e) {
      //   e.preventDefault();
      //   e.returnValue = "このページを離れますか？";
      //   return "このページを離れますか？";
      // });
    })
}

function changeLoadingState(state, loadMsg, submitMsg, submitButton = null, modalEle = null) {
  let submitBtn = submitButton || document.getElementById("payment-submit")

  submitBtn.disabled = state
  submitBtn.innerHTML = state
    ? `  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>${loadMsg}...`
    : submitMsg
  // return err if submitBtn is already payment-submit-loading
  if (state && submitBtn.classList.contains("payment-submit-loading")) {
    return true
  }

  submitBtn.classList.toggle("payment-submit-loading", state)

  var myModalEl = modalEle || document.getElementById("payment-method-modal")
  // new bootstrap.Modal(myModalEl, {
  //   backdrop: "static",
  //   keyboard: false,
  // });
  if (state) {
    myModalEl &&
      myModalEl.addEventListener("hide.bs.modal", (e) => {
        e.preventDefault()
      })
  } else {
    myModalEl &&
      myModalEl.removeEventListener("hide.bs.modal", (e) => {
        e.preventDefault()
      })
  }
  return false
}

/**
 * Create a new subscription
 * @param {Object} stripe - Stripe instance
 * @param {String} cardNumber - Stripe cardNumber instance
 * @param {String} email
 * @param {String} password
 * @param {string} successTag - Success tag
 * @param {(event) => void} displayErrorFn - Submit button
 */
function createSubscription(stripe, cardElement, email, password, returnUrl, displayErrorFn) {
  return (
    fetch("/payments/create_subscription", {
      method: "post",
      headers: {
        "Content-type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        email: email,
        password: password,
        // paymentMethodId: paymentMethodId,
        // couponKey: couponKey,
      }),
    })
      .then((response) => {
        return response.json()
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer
          throw result
        }
        return result
      })
      // Normalize the result to contain the object returned
      // by Stripe. Add the addional details we need.
      .then(
        ({ subscriptionId, clientSecret, setupIntentClientSecret, contactName, contactEmail }) => {
          // if (setupIntentClientSecret) {
          // 一般ユーザーだとこのケースはない、、、
          //
          //   // 支払いが未来の場合（トライアル終了前）の場合すぐに決済がはしらないので、
          //   // setUpIntent を実行する感じ
          //   let result = stripe
          //     .confirmCardSetup(setupIntentClientSecret, {
          //       payment_method: {
          //         card: cardElement,
          //         billing_details: {
          //           name: contactName,
          //         },
          //       },
          //       return_url: url + "#change-plan", // Specify return URL
          //     })
          //     .then((result) => {
          //       if (result.error) {
          //         // alert(result.error.message);
          //         displayErrorFn(result)
          //       } else {
          //         // Successful subscription payment
          //         // ローカルではwebhookをみないのでoncompleteする
          //         onSubscriptionComplete(subscriptionId, successTag, displayErrorFn)
          //       }
          //     })
          //     .catch((error) => {
          //       // An error has happened. Display the failure to the user here.
          //       // We utilize the HTML element we created.
          //       console.log("err", error)
          //       displayErrorFn(error)
          //     })
          //   if (result.error) {
          //     displayErrorFn(result)
          //   }
          // } else
          {
            let result = stripe
              .confirmCardPayment(clientSecret, {
                payment_method: {
                  card: cardElement,
                  billing_details: {
                    name: contactName,
                  },
                },
                return_url: returnUrl, // Specify return URL
                receipt_email: contactEmail,
              })
              .then((result) => {
                if (result.error) {
                  displayErrorFn(result)
                } else {
                  // Successful subscription payment
                  // ローカルではwebhookをみないのでoncompleteする
                  onSubscriptionComplete(subscriptionId, returnUrl, displayErrorFn)
                }
              })
              .catch((error) => {
                // An error has happened. Display the failure to the user here.
                // We utilize the HTML element we created.
                console.log("err", error)
                displayErrorFn(error)
              })
            if (result.error) {
              displayErrorFn(result.error)
            }
          }
          // return {
          //   // Use the Stripe 'object' property on the
          //   // returned result to understand what object is returned.
          //   stripe: stripe,
          //   subscription: result,
          //   paymentMethodId: paymentMethodId,
          //   priceId: result.priceId,
          // };
          // // Some payment methods require a customer to do additional
          // // authentication with their financial institution.
          // // Eg: 2FA for cards.
          // .then(handlePaymentThatRequiresCustomerAction)
          // // If attaching this card to a Customer object succeeds,
          // // but attempts to charge the customer fail. You will
          // // get a requires_payment_method error.
          // .then(handleRequiresPaymentMethod)
          // // No more actions required. Provision your service for the user.
          // .then(onSubscriptionComplete)
        }
      )
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        console.log("err", error)
        displayErrorFn(error)
      })
  )
}

function onSubscriptionComplete(subscriptionId, returnUrl, displayErrorFn) {
  if (subscriptionId) {
    completeSubscription(subscriptionId)
      .then((response) => {
        if (response.status !== 200) {
          console.log("error completeSubscription", response)
          window.location.hash = ""
          window.location.reload()
          flashAlert("エラーが発生しました。至急サポートにお問い合わせください。", "danger")
          return
        }

        window.location.href = returnUrl
        window.location.reload()

        // flashAlert("お支払いありがとうございます！", "success")
      })
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        console.log("error completeSubscription", error)
        displayErrorFn(error)
      })
  } else {
    console.log("can't complete onSubscriptionComplete", result)
    // 想定外だけどリロードしておく
    // window.location.reload()
  }
}

function completeSubscription(subscriptionId, displayErrorFn) {
  return fetch("/payments/complete?subscriptionId=" + subscriptionId, {
    method: "post",
    headers: {
      "Content-type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
  }).catch((error) => {
    // An error has happened. Display the failure to the user here.
    // We utilize the HTML element we created.
    displayErrorFn(error)
  })
}

function changePaymentMethodModalInit(stripe) {
  let elements = stripe.elements()
  const changeElementStyles = {
    base: {
      color: "#32325D",
      fontWeight: 500,
      fontFamily: "Noto Sans JP",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
      ":-webkit-autofill": {
        color: "#e39f48",
      },
    },
    invalid: {
      color: "#E25950",
      "::placeholder": {
        color: "#FFCCA5",
      },
    },
  }
  const changeElementClasses = {
    focus: "focused",
    empty: "empty",
    invalid: "invalid",
  }
  // 変更モーダルがないので落とす
  if (!document.getElementById("change-payment-method-modal")) {
    return
  }
  let changeCardNumber = elements.create("cardNumber", {
    style: changeElementStyles,
    classes: changeElementClasses,
    showIcon: true,
  })
  changeCardNumber.mount("#change-payment-card-number")
  changeCardNumber.focus()

  let changeCardExpiry = elements.create("cardExpiry", {
    style: changeElementStyles,
    classes: changeElementClasses,
  })
  changeCardExpiry.mount("#change-payment-card-expiry")

  let changeCardCvc = elements.create("cardCvc", {
    style: changeElementStyles,
    classes: changeElementClasses,
  })
  changeCardCvc.mount("#change-payment-card-cvc")

  var myModalEl2 = document.getElementById("change-payment-method-modal")
  myModalEl2 &&
    myModalEl2.addEventListener("show.bs.modal", function (event) {
      // TODO payment element を使う。。。
      // let card = elements.create("card", {
      //   style: elementStyles,
      //   hidePostalCode: true,
      // });
      // card.mount("#card-element");

      // card.on("change", function (event) {
      //   displayError(event);
      // });
      let changePaymentForm = document.getElementById("change-payment-submit")
      if (changePaymentForm) {
        changePaymentForm.addEventListener("click", (evt) => {
          evt.preventDefault()
          let submitBtn = document.getElementById("change-payment-submit")
          let messageEle = document.getElementById("change-payment-method-error-message")
          const err = changeLoadingState(true, "登録中...", "登録する", submitBtn, messageEle)
          if (err) {
            return
          }
          if (!changeCardNumber) return

          fetch(`/payments/payment_status`)
            .then((response) => {
              return response.json()
            })
            .then((data) => {
              // クレカ登録済みのトライアル、サブスク中、決済失敗の場合は changePaymentMethod する
              if (data && data.has_payment_method && data.contract_status != "suspend") {
                changePaymentMethod(stripe, changeCardNumber, (event) => {
                  displayError(event, submitBtn, myModalEl2, messageEle)
                })
                console.log("change payment method")
              }
            })
            .catch((err) => {
              displayError(err, submitBtn, myModalEl2, messageEle)
            })
        })
      }
      // ページ移動の警告 不要？
      // $(window).on("beforeunload", function (e) {
      //   e.preventDefault();
      //   e.returnValue = "このページを離れますか？";
      //   return "このページを離れますか？";
      // });
    })
}

// 以下Stripeのサンプルより
// https://stripe.com/docs/billing/subscriptions/per-seat?locale=ja-JP
// https://stripe.com/docs/billing/subscriptions/build-subscriptions?ui=elements
function changePaymentMethod(stripe, cardNumber, displayErrorFn) {
  stripe
    .createPaymentMethod({
      type: "card",
      card: cardNumber,
    })
    .then((result) => {
      if (result.error) {
        displayErrorFn(result)
      } else {
        fetch(`/payments/change?paymentMethodId=${result.paymentMethod.id}`, {
          method: "POST",
        })
          .then((response) => {
            return response.json()
          })
          .then((json) => {
            if (json.error) {
              displayErrorFn(json)
            } else {
              // 成功
              window.location.hash = "#change-plan"
              window.location.reload()
            }
          })
      }
    })
}

/**
 *
 * @param {*} event
 * @param {HTMLElement?} submitBtn
 * @param {HTMLElement?} modalEle
 * @param {HTMLElement?} messageEle
 */
function displayError(event, submitBtn, modalEle, messageEle) {
  changeLoadingState(false, "登録中...", "登録する", submitBtn, modalEle)

  if (event.error) {
    const msgEle = messageEle || document.getElementById("payment-method-error-message")

    if (event.error) {
      //   msgEle.innerHTML = event.detail[0].messages
      //     .map((s) => `<li>${s}</li>`)
      //     .join("");

      //   msgEle.classList.add("visible");
      // } else {
      msgEle.innerHTML = event.error.message
      msgEle.classList.add("visible")
      console.log(event.error.message)
    }
  }
}
