export default function flashAlert(message, type) {
  var alertPlaceholder = document.getElementById("flash-alert-js")
  var wrapper = document.createElement("div")
  wrapper.className = "alert_init_pos"
  const icon_class = type == "danger" ? "fa-circle-exclamation" : "fa-info-circle"
  wrapper.innerHTML =
    `<div class="d-flex justify-content-between alert alert-${type} " role="alert"><div class="d-flex justify-content-start"><i class="fas ${icon_class} icon"></i><div class="message">` +
    message +
    '</div></div><span class="material-icons-outlined close" data-bs-dismiss="alert" aria-label="Close">close</span></div>'

  alertPlaceholder.append(wrapper)

  setTimeout(function () {
    wrapper.classList.add("move_alert")
  }, 6000)
  setTimeout(function () {
    wrapper.remove()
  }, 6300)
}
