/**
 * サービス停止のバナーが表示された際に、幅を取得してbodyのcss変数に設定
 * FIXME: バナー幅処理が重複(adjustFillerHeight())しているのでまとめる
 */
function measureBanner() {
  const bannerHeight =
    document.querySelector(".alert_payment_error")?.getBoundingClientRect()?.height || 0
  document.querySelector("body").style.setProperty("--banner-height", bannerHeight + "px")
}

/**
 * スクロールバーの表示/非表示が切り替わった際に幅を取得してbodyのcss変数に設定
 */
function measureScrollbarWidth() {
  const resizeObserver = new ResizeObserver((entries) => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth
    document.body.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`)
  })
  resizeObserver.observe(document.body)
}

/**
 * 使用方法: 対象要素にdata-js-tooltip="hogehoge"を設定
 * bizのtooltipからの変更点: ツールチップのtopとleftにスクロールを含めていない
 *
 */
function customerJsTooltip() {
  /**
   * 初期状態: #customer-dynamic-tooltip display:none, opacity: 0
   * トランジション開始: #customer-dynamic-tooltip display:block(<-jsで変更), opacity: 0
   * 表示状態: #customer-dynamic-tooltip.show display:block, opacity: 1
   **/
  const tooltipEl = document.getElementById("customer-dynamic-tooltip")

  const showTooltip = (e) => {
    const adjustTooltip = () => {
      const el = e.currentTarget
      const elRect = el.getBoundingClientRect()
      tooltipEl.innerText = el.dataset.jsTooltip
      tooltipEl.style.display = "block"
      tooltipEl.style.whiteSpace = "nowrap"
      tooltipEl.style.top = `${elRect.top - tooltipEl.getBoundingClientRect().height - 8}px`
      tooltipEl.style.left = `${
        elRect.left + 0.5 * elRect.width - tooltipEl.getBoundingClientRect().width / 2
      }px`
      tooltipEl.style.zIndex = "1000"
    }

    // 前回表示されたツールチップが画面端にあって改行していた場合にずれて表示されることがあるので、2回位置調整を行う
    adjustTooltip()
    adjustTooltip()

    tooltipEl.classList.add("show")
  }
  const hideTooltip = () => {
    tooltipEl.classList.remove("show")
  }

  document.querySelectorAll("[data-js-tooltip]").forEach((el) => {
    el.addEventListener("mouseenter", showTooltip)
    el.addEventListener("click", hideTooltip)
    el.addEventListener("mouseleave", hideTooltip)
  })
}

document.addEventListener("turbolinks:load", function () {
  if (document.querySelector("body.customer-body")) {
    measureBanner()
    window.addEventListener("resize", measureBanner)
  }

  measureScrollbarWidth()

  // data-js-tooltip属性で指定するツールチップ
  customerJsTooltip()
})
